import React, { useState } from 'react';
import { useLocation } from '@reach/router';
import { event } from '@analytics/gtm';
import cn from 'classnames';

import { Accordion } from '@components/Blocks/Accordion';

import iconGift from '@images/icons/web-gift-blue.svg';
import iconDiscount from '@images/icons/discount.svg';
import iconDocTime from '@images/icons/docTime.svg';
import iconArrowDown from '@images/icons/selector-arrow-down.svg';
import iconArrowUp from '@images/icons/selector-arrow-up.svg';
import iconSupport from '@images/icons/icon-support.svg';
import Images from '@components/Images';
import GiftForm from './GiftForm';

import styles from './GiftPage.module.scss';

const icons = [iconGift, iconDiscount, iconDocTime, iconSupport];

function GiftPage() {
  const location = useLocation();
  const [isOpenList, setIsOpenList] = useState(false);

  const toggleOpenList = () => setIsOpenList(!isOpenList);
  const about = [
    {
      id: 1,
      text: 'Выберите готовый дизайн или загрузите своё изображение в шаблон',
    },
    {
      id: 2,
      text: 'Отправьте получателю на электронную почту или распечатайте, чтобы вручить лично',
    },
    {
      id: 3,
      text: 'Сертификат можно использовать несколько раз до исчерпания баланса',
    },
    {
      id: 4,
      text: 'Сертификат действует 1 год с момента получения',
    },
  ];
  const handleAccordionClick = () => {
    event('event_site_gift_accordeon-click');
  };

  const faqItems = [
    {
      groupTitle: '',
      groupRank: 0,
      groupItems: [
        {
          title: 'На что действует сертификат?',
          answer:
            'Сертификатом можно частично или полностью оплатить заказ. Его можно потратить на любую печатную продукцию Периодики, а также на услугу <a href="/design-photobook" target="_blank">«Соберем фотокнигу за вас»</a>',
          rank: 0,
        },
        {
          title: 'Как активировать сертификат?',
          answer:
            'Код сертификата вводится в поле для промокода на финальном этапе оформления заказа — в корзине.',
          rank: 1,
        },
        {
          title: 'Сколько действует сертификат?',
          answer:
            'Срок действия наших сертификатов — 12 месяцев с момента оплаты. Сертификат можно использовать несколько раз до исчерпания баланса и он не суммируется с другими промокодами и акциями.',
          rank: 2,
        },
      ],
    },
  ];
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.block}>
          <div className={styles.main}>
            <Images className={styles.GiftPhoto} src="gift_head_may8.jpg" alt="giftPhoto" />
            <h1 className={styles.h1}>Подарочный сертификат</h1>
            <div className={styles.desktop}>
              <div className={styles.list}>
                {about.map((a, idx) => (
                  <div className={styles.list_item} key={a.id}>
                    <img src={icons[idx]} alt="icon" className={styles.list_icon} />
                    <p
                      className={styles.p3}
                      key={a.id}
                      dangerouslySetInnerHTML={{ __html: a.text }}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.mobile}>
              <button type="button" className={styles.aboutButton} onClick={toggleOpenList}>
                О продукте
                <img src={isOpenList ? iconArrowUp : iconArrowDown} alt="down icon" />
              </button>
              <div
                className={cn({
                  [styles.list]: true,
                  [styles.hideList]: !isOpenList,
                  [styles.showList]: isOpenList,
                })}
              >
                {about.map((a, idx) => (
                  <div className={styles.list_item} key={a.id}>
                    <img src={icons[idx]} alt="icon" className={styles.list_icon} />
                    <p className={styles.p3} dangerouslySetInnerHTML={{ __html: a.text }} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <GiftForm values={location?.state as any} />
      <div className={styles.FAQBlock}>
        <h3 className={styles.h3}>Вопросы и ответы</h3>
        <div onClick={handleAccordionClick}>
          <Accordion title="" items={faqItems} />
        </div>
      </div>
    </>
  );
}

export default GiftPage;
